$filterBackColor: var(--tg-theme-bg-color);
$filterTrackColor: var(--tg-theme-bg-color);
//$filterTrackColor: #7ef175;

.filter-area {
  display: flex;
  flex-direction: column;
}

.multi-select {
  text-align: center;
  width: 100%;
  margin: 10px auto 0;
  color: black;

  div {
    //z-index: 2;
  }
}

input {
  //box-shadow: 0;
  outline: 0;
}

.range-slider {
  //width: 200px;
  margin: 5px auto 30px;
  text-align: center;
  position: relative;
  //height: 6em;
}

.range-slider span {
  display: flex;
  justify-content: center;
}

.range-slider svg,
.range-slider input[type='range'] {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: -40px 0;
}

input[type='number'] {
  margin: 0 8px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 14px;
  -moz-appearance: textfield;
  width: 100px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number']:invalid,
input[type='number']:out-of-range {
  border: 2px solid #ff6347;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: $filterBackColor;
}

input[type='range']:focus::-ms-fill-lower {
  background: $filterBackColor;
}

input[type='range']:focus::-ms-fill-upper {
  background: $filterBackColor;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: $filterBackColor;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type='range']::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0 0 0 #000;
  border: 1px solid $filterBackColor;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: $filterTrackColor;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: $filterBackColor;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type='range']::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0 0 0 #000;
  border: 1px solid $filterBackColor;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: $filterTrackColor;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-ms-fill-lower,
input[type='range']::-ms-fill-upper {
  background: $filterBackColor;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type='range']::-ms-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0 0 0 #000;
  border: 1px solid $filterBackColor;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: $filterTrackColor;
  cursor: pointer;
}