$bg_color: var(--tg-theme-bg-color);
$text_color: var(--tg-theme-text-color);
$hint_color: var(--tg-theme-hint-color);
$link_color: var(--tg-theme-link-color) !default;

$link_color: #23527c;
$button_color: var(--tg-theme-button-color);
$button_text_color: var(--tg-theme-button-text-color);
$secondary_bg_color: var(--tg-theme-secondary-bg-color);

$brand-primary: #57c150;
$link-color: $link_color; //#23527c;
$body-bg: $bg_color; //#f2f2f2;
$font-family-base: Roboto, Arial, sans-serif;
$font-family-header-quote: Kurale, Arial, sans-serif;
$font-size-base: 0.85rem; //14px;
/*
$container-large-desktop: 1024px;
$container-lg: 1024px;
*/

$header-height: 38.2%;
$header-height-big: 61.2%;
//$header-cover-background: fade(darken($brand-primary, 30%), 40%);
$header-cover-background: rgba(35, 89, 31, .4);
$header-quote-font-size: 45px;
$header-quote-author-font-size: 13px;
$header-quote-color: #ffffff;
$navigation-top-margin: 32px;
$navigation-color: #ffffff;
$navigation-bg-color-hover: darken($brand-primary, 20%);
$navigation-bg-color: fade(darken(desaturate($brand-primary, 35%), 25%), 60%);
$navigation-border-radius: 22px;
$navigation-logotype-size: 44px; //50
$navigation-logotype-border: 5px; //4
$navigation-link-width: 100px;
$navigation-link-count: 6;
$navigation-before-logotype: 30px; //20
$navigation-width: calc($navigation-link-width * $navigation-link-count + $navigation-before-logotype);
$navigation-logotype-left: calc($navigation-width / 2 - $navigation-logotype-border - $navigation-logotype-size / 2);
$navigation-mobile-width: 220px;
$navigation-mobile-transition-duration: 0.3s;

$event-title-color: $text_color; //#ffffff;
$event-title-background: $brand-primary;
$event-panel-height: 42px;
$event-panel-background: $bg_color; //#ffffff;
$event-panel-border-color: $button_color; // #dfdfdf;
$event-panel-font-color: $text_color; //#808080;
$event-content-background: $bg_color; //#ffffff;

$graditude-font-color: #808080;
$graditude-links-font-color: $brand-primary;

$about-slide-nav-font-color: #ffffff;
$about-slide-nav-background: fade(#000000, 40%);
$about-nav-item-color: #ffffff;
$about-background-color: #ffffff;
$about-price-font-size: 22px;

$feed-header-color: $hint_color;

$footer-logotype-size: 100px;
$footer-time-font-color: darken($brand-primary, 20%);
$footer-background: $brand-primary;
$footer-base-font-color: #ffffff;

$rent-control-border-color: #cccccc;
$rent-control-active-border-color: #000000;
$rent-description-font-color: #ffffff;
$rent-room-info-font-size: 16px;
$rent-room-description-background: fade(#000000, 40%);
$rent-contact-font-color: #000000;

$address-block-background: #ffffff;
$address-block-width: 250px;
$contacts-house-font-size: 20px;
$contacts-how-to-go-color: #ffffff;
$contacts-link-color: $link-color;
$contacts-social-container-background: #7669cb;
$contacts-social-font-size: 43px;
$contacts-social-background: #aa9dff;
$contacts-social-size: 85px;
$contacts-social-color: #ffffff;
$contacts-partners-container-background: #4a5b53;
$contacts-partners-font-color: #77a791;
$contacts-blazon-font-color: #979797;
$contacts-street-sign-size: 165px;

$ribbon-content-font-size: 24px;

$screen-xs-max: 900px; //map-get($container-max-widths, 'lg');
$breadcrumb-bg: rgba(119, 167, 145, 0.53);
