
@import "~bootstrap/scss/functions";
@import "variables";
@import "bootstrap/variables";
@import "~bootstrap/scss/bootstrap";
@import "App";
@import "shop/index";
@import "pages/event";
@import "components/ribbon";
@import "components/header";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "ext/phone";

:root {
/*  --tg-theme-bg-color: #fff;
  --tg-theme-text-color: #0a0a0a;
  --tg-theme-hint-color: #929292;
  --tg-theme-link-color: #207ae4;
  --tg-theme-button-color: #5bc8fb;
  --tg-theme-button-text-color: #fffeec;
  --tg-theme-secondary-bg-color: #f3f2f9;*/

  --tg-theme-bg-color: #18222c;
  //--tg-theme-text-color: #e6e6e6;
  --tg-theme-text-color: #ffde58;
  --tg-theme-hint-color: #b2c3d5;
  --tg-theme-button-color: #2fa5ff;
  //--tg-theme-button-text-color: #ffffff;
  --tg-theme-button-text-color: #FFDE58FF;
  --tg-theme-secondary-bg-color: #191117;

  --default-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
}

pre {
  background-color:$text_color ;
  color: $bg_color;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


/*
:root {
  --tg-theme-bg-color: #18222c;
  //--tg-theme-text-color: #e6e6e6;
  --tg-theme-text-color: #ffde58;
  --tg-theme-hint-color: #b2c3d5;
  --tg-theme-button-color: #2fa5ff;
  //--tg-theme-button-text-color: #ffffff;
  --tg-theme-button-text-color: #FFDE58FF;
  --tg-theme-secondary-bg-color: #191117;
}
*/


body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: $bg_color;

  background-color: $bg_color;
  color: $text_color;
  //background: var(--tg-theme-secondary-bg-color);
  //padding: 20px 20px;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

}


body {

  // background: var(--tg-theme-bg-color);
  // color: var(--tg-theme-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox:checked {
  accent-color: var(--tg-theme-button-color);
  //border: 1px solid
 // background-color: red;
}
/*
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--tg-theme-button-color);
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}*/
