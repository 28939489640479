
.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8em;
  background-color: var(--tg-theme-bg-color) !important;
  color: #000;
  border: 1px solid var(--tg-theme-secondary-bg-color);
  border-radius: 0.3em;
  display: inline-block;
  position: relative;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3em;
  background-color: var(--tg-theme-bg-color);
}

.react-datepicker__header {
  text-align: center;
  background-color: var(--tg-theme-bg-color) !important;
  color: var(--tg-theme-text-color) !important;
  border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
  border-top-left-radius: 0.3em;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__month {
  background-color: var(--tg-theme-bg-color) !important;
  color: var(--tg-theme-text-color) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {

  background-color: var(--tg-theme-bg-color) !important;
  color: var(--tg-theme-text-color) !important;
}

.react-datepicker-wrapper {
  position: relative !important;
  flex: 1 1 auto !important;
  width: 1% !important;
  min-width: 0 !important;
  margin-bottom: 0 !important;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: var(--tg-theme-button-color) !important;
}


.bgTgBtn {
  background-color: var(--tg-theme-button-color) !important;
}

.react-datepicker-time__header {
  color: var(--tg-theme-button-text-color) !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3em;
  background-color: var(--tg-theme-button-color) !important;
  color: var(--tg-theme-button-text-color) !important;
}

.react-datepicker__day {
  color: var(--tg-theme-button-text-color) !important;
}

.react-datepicker__day-name, {
  color: var(--tg-theme-button-text-color) !important;
}

.react-datepicker__current-month, {
  color: var(--tg-theme-button-text-color) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--tg-theme-button-color) !important;
  color: var(--tg-theme-button-text-color) !important;
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  // color: $link_color;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
