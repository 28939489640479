.event {
  height: 100%;
}

.event-cover {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.event-title-container {
  position: relative;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  vertical-align: middle;
  color: $event-title-color;

/*  .ribbon {
    @media (max-width: $screen-xs-max) {
      width: 100%;
      padding: 15px;
      text-align: left;
      &:after,
      &:before,
      .ribbon-content:after,
      .ribbon-content:before {
        display: none;
      }
    }
  }*/

/*  @media (max-width: $screen-xs-max) {
    top: inherit;
   !* bottom: $event-panel-height;*!
    text-align: left;
  }*/
}

.event-panel-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $event-panel-height;
  border-top: 1px solid $event-panel-border-color;
  border-bottom: 1px solid $event-panel-border-color;
  background: $event-panel-background;
}

.event-title-date {
  display: inline-block;
  margin-top: 80px;
  padding: 15px;
  border-radius: 3px;
  background: rgba(65, 82, 64, .6);;

/*  @media (max-width: $screen-xs-max) {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    background: darken($brand-primary, 20%);
  }*/
}

.event-panel {
  color: $event-panel-font-color;

  //.container();
}

.event-panel-item {
  display: inline-block;
  //padding: 10px 10px;
  border-left: 1px solid var( --tg-theme-text-color);

  &:first-child {
    padding-left: 0;
    border-left: 0;
  }

  @media (max-width: $screen-xs-max) {
    padding-right: 3px;
    padding-left: 3px;

    &.community {
      display: none;
    }
  }
}

.event-content-container {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  background: $event-content-background;
}

.event-content {
  // .container();
}

.event-gratitude {
  margin-top: 20px;
  color: $graditude-font-color;
}

.event-gratitude-link {
  text-decoration: none;
  color: $graditude-links-font-color;

  &:hover {
    color: darken($graditude-links-font-color, 15%);
  }
}

.event-card-container {
  background-position: 50%;
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(74, 91, 83, .1), 0 1px 2px rgba(0, 0, 0, .1);
  height: 300px;
  margin-bottom: 30px;
  position: relative;
}

.event-panel-social {
  padding: 0;
}

.event-panel-social-item {
  display: inline-block;
  padding: 10px 0;
  color: $event-panel-font-color;
  font-size: 18px;

  &:hover {
    color: $brand-primary;
  }

  &.facebook {
    margin-left: 7px;
    padding-left: 7px;
    border-left: 1px solid $event-panel-border-color;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

$border-size: 25px;

.feed-header {
  padding: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  color: $feed-header-color;
  font-size: 14px;
  font-weight: 400;

  &:after {
    display: block;
    margin-top: -13px;
    margin-bottom: 12px;
    margin-left: -5.5px;
    content: "";
    border-bottom: 1px solid $feed-header-color;
  }
}

.feed-header-time {
  padding: 0 22px;
  background: $body-bg;
}

.event-card-link {
  display: block;
  text-decoration: none;
  color: #000000;
}

.event-card-container {
  position: relative;
  height: 300px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(74, 91, 83, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.event-card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: $text_color //rgb(66, 66, 66);
}

.event-card-header {
  margin: 0;
  padding: 11px 22px;
  background: var(--tg-theme-button-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 18px * 1.5;
}


.event-card-meta {
  display: inline-block;
  min-width: 200px;
  max-width: 300px;
  height: 1rem;
  margin: auto;
  margin-bottom: 0;
  padding: 3.264px 14.264px;
  vertical-align: bottom;
  text-transform: uppercase;
  border-right: $border-size solid transparent;
  border-bottom: $border-size solid var(--tg-theme-button-color);
  border-left: $border-size solid transparent;
  border-top-right-radius: 100px;
  background: none;
  font-size: 12px;
  line-height: 2.4rem;


}

.feed-past-events {
  text-align: center;
}

.feed-link {
  color: $link-color;
  text-decoration: none;
}

.feed-hookah-image {
  width: 50%;
  height: auto;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    position: relative;
    bottom: 8%;
    z-index: 20;
    color: white;
    //left: 45%;
    font-size: 30px;
  }
}

.feed-hookah-container {
  display: flex;
  flex-wrap: wrap
}

@media screen and (max-width: 770px) {
  .feed-hookah-image {
    width: 100%
  }
}
