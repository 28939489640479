.two-lines {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-height: 3.4rem;
  white-space: normal;
}
.ellipse {
  //display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
}

.section-products {
  padding-top: 10px;
}

.rentStatus {
  position: absolute;
  z-index: 1;
}

.rentStatus button {
  font-size: 12px;
  margin-top: 3px;
}

.section-products .header {
  margin-bottom: 50px;
}

.section-products .header h3 {
  font-size: 1rem;
  color: #fe302f;
  font-weight: 500;
}

.section-products .header h2 {
  font-size: 2.2rem;
  font-weight: 400;
  color: #444444;
}

.section-products .single-product {
  margin-bottom: 10px;
  background-color: white;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.section-products .single-product .part-1 {
  position: relative;
  height: 200px;
  max-height: 200px;
  margin: 4px 4px 20px;
  overflow: hidden;
}


.section-products .single-product:hover .product-image {
  transform: scale(1.1, 1.1) rotate(5deg);
  //transform: scale(1.02);
}

.product-image {
  background: url("../../../public/images/logotype.svg") no-repeat center;
  background-size: cover;
  transition: all 0.3s;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  //max-width: 300px;
  width: 100%;
  height: 100%;
  //z-index: -10;
}


.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
  position: absolute;
  top: 15px;
  left: 20px;
  color: #ccc;
  background-color: #fe302f;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 0.85rem;
}

.section-products .single-product .part-1 .new {
  left: 0;
  background-color: #444444;
}

.section-products .single-product .part-1 ul {
  position: absolute;
  bottom: -41px;
  left: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
  bottom: 30px;
  opacity: 1;
}

.section-products .single-product .part-1 ul li {
  display: inline-block;
  margin-right: 4px;
}

.section-products .single-product .part-1 ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  color: #444444;
  text-align: center;
  box-shadow: 0 2px 20px rgb(50 50 50 / 10%);
  transition: color 0.2s;
}

.section-products .single-product .part-1 ul li a:hover {
  color: #fe302f;
}

.section-products .single-product .part-2 {
  padding: 0 8px;

}

.section-products .single-product .part-2 .product-title {
  font-size: 1rem;
  text-align: center;
}

.section-products .single-product .part-2 h4 {
  display: inline-block;
  font-size: 1rem;
}

.section-products .single-product .part-2 .product-old-price {
  position: relative;
  padding: 0 7px;
  margin-right: 2px;
  opacity: 0.6;
}

.section-products .single-product .part-2 .product-old-price::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #444444;
  transform: translateY(-50%);
}


.product-card-container {
  position: relative;
  height: 300px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(74, 91, 83, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.product-card-link {
  display: block;
  text-decoration: none;
  color: #000000;
}


.product-card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: rgb(66, 66, 66);
}

.product-card-header {
  margin: 0;
  padding: 11px 22px;
  background: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px * 1.5;
}


